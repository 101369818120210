import { memo } from "react"

interface OnboardingStepHeaderProps {
  currentStep?: number
  totalSteps?: number
  title: string
  description: string
}

export const OnboardingStepHeader: React.FC<OnboardingStepHeaderProps> = memo(
  (props: OnboardingStepHeaderProps) => {
    return (
      <div className="flex-none mb-8">
        {props.currentStep !== undefined && props.totalSteps !== undefined && (
          <div className="text-sm text-low-contrast tabular-nums">
            0{props.currentStep} / 0{props.totalSteps}
          </div>
        )}
        <div className="text-xxl mt-4 font-bold leading-tight text-high-contrast">
          {props.title}
        </div>
        <div className="text-low-contrast text-base mt-2">
          {props.description}
        </div>
      </div>
    )
  },
)

OnboardingStepHeader.displayName = "OnboardingStepHeader"
