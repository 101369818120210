import { Button, ButtonVariant, ButtonSize } from "@daybridge/components-core"
import { memo } from "react"

interface OnboardingStepFooterProps {
  navigate: (increment: number) => void
  continueText?: string
  backDisabled?: boolean
  continueDisabled?: boolean
  skipStepsBackward?: number
  skipStepsForward?: number
}

export const OnboardingStepFooter: React.FC<OnboardingStepFooterProps> = memo(
  (props: OnboardingStepFooterProps) => {
    return (
      <div className="flex space-x-5 mt-auto pt-8">
        <Button
          block
          variant={ButtonVariant.Outline}
          size={ButtonSize.Medium}
          onPress={() => props.navigate(-(1 + (props.skipStepsBackward || 0)))}
          disabled={props.backDisabled}
        >
          Back
        </Button>
        <Button
          block
          variant={ButtonVariant.Primary}
          size={ButtonSize.Medium}
          onPress={() => props.navigate(1 + (props.skipStepsForward || 0))}
          disabled={props.continueDisabled}
        >
          {props.continueText || "Continue"}
        </Button>
      </div>
    )
  },
)

OnboardingStepFooter.displayName = "OnboardingStepFooter"
