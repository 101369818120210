import { IconId } from "@daybridge/client-api"
import {
  StackedRadioCards,
  Popover,
  Tooltip,
  Button,
  ButtonVariant,
  ButtonSize,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React, { useState } from "react"
import Image from "next/image"
import { OnboardingStepFooter } from "../../onboarding/components/OnboardingStepFooter"
import { OnboardingStepHeader } from "../../onboarding/components/OnboardingStepHeader"

type OperatingSystem = "ios" | "android"

interface OnboardingMetatata {
  currentStep: number
  totalSteps: number
  navigate: (steps: number) => void
  isLoading: boolean
  skipStepsBackward: number
}

interface DownloadMobileAppsProps {
  onboardingMetadata?: OnboardingMetatata
}

const DownloadMobileAppsFn = (props: DownloadMobileAppsProps) => {
  const { onboardingMetadata } = props
  const [operatingSystem, setOperatingSystem] = useState<OperatingSystem>("ios")

  return (
    <>
      <div className="w-[440px] flex-shrink-0 p-12 flex flex-col">
        <OnboardingStepHeader
          currentStep={onboardingMetadata?.currentStep}
          totalSteps={onboardingMetadata?.totalSteps}
          title="Get the Mobile App"
          description="Take Daybridge with you wherever you go. Download our apps for iOS and Android."
        />

        <StackedRadioCards<OperatingSystem>
          showTick
          value={operatingSystem}
          onValueChange={setOperatingSystem}
          className="-m-2"
          options={[
            {
              value: "ios",
              label: "iOS",
              description: "Download from the App Store",
              image: (
                <>
                  <div className="dark:hidden">
                    <Image
                      alt="iOS icon"
                      src={`/assets/onboarding/mobile/ios-icon-light-${
                        operatingSystem === "ios" ? "active" : "inactive"
                      }.svg`}
                      width={52}
                      height={52}
                      layout="fixed"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Image
                      alt="iOS icon"
                      src={`/assets/onboarding/mobile/ios-icon-dark-${
                        operatingSystem === "ios" ? "active" : "inactive"
                      }.svg`}
                      width={52}
                      height={52}
                      layout="fixed"
                    />
                  </div>
                </>
              ),
            },
            {
              value: "android",
              label: "Android",
              description: "Download from the Play Store",
              image: (
                <>
                  <div className="dark:hidden">
                    <Image
                      alt="Android icon"
                      src={`/assets/onboarding/mobile/android-icon-light-${
                        operatingSystem === "android" ? "active" : "inactive"
                      }.svg`}
                      width={52}
                      height={52}
                      layout="fixed"
                    />
                  </div>
                  <div className="hidden dark:block">
                    <Image
                      alt="Android icon"
                      src={`/assets/onboarding/mobile/android-icon-dark-${
                        operatingSystem === "android" ? "active" : "inactive"
                      }.svg`}
                      width={52}
                      height={52}
                      layout="fixed"
                    />
                  </div>
                </>
              ),
            },
          ]}
        />

        {onboardingMetadata && (
          <OnboardingStepFooter
            navigate={onboardingMetadata.navigate}
            backDisabled={onboardingMetadata.isLoading}
            skipStepsBackward={onboardingMetadata.skipStepsBackward}
          />
        )}
      </div>
      <div className="flex-1 flex overflow-hidden justify-center relative">
        {operatingSystem === "ios" && (
          <>
            <div className="dark:hidden h-[696px]">
              <Image
                alt="Daybridge for iOS"
                src="/assets/onboarding/mobile/ios-light.svg"
                width={720}
                height={696}
                layout="fixed"
              />
            </div>
            <div className="hidden dark:block h-[696px]">
              <Image
                alt="Daybridge for iOS"
                src="/assets/onboarding/mobile/ios-dark.svg"
                width={720}
                height={696}
                layout="fixed"
              />
            </div>
            <div className="flex flex-row space-x-2 absolute right-0 top-0 p-6">
              <Popover
                content={
                  <div className="p-6 w-60">
                    <h2 className="text-high-contrast text-lg font-bold">
                      Scan to Download
                    </h2>
                    <p className="mb-4 text-low-contrast text-base">
                      Use your phone&apos;s camera to scan this QR code.
                    </p>
                    <Image
                      alt="App Store QR code"
                      width={208}
                      height={200}
                      src="/assets/onboarding/mobile/code-appstore.svg"
                    />
                  </div>
                }
              >
                <Tooltip content="Open QR code">
                  <Button
                    variant={ButtonVariant.Outline}
                    size={ButtonSize.Medium}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-icon-decorative mr-2"
                    >
                      <path
                        d="M10.1111 10.8889V10.1111H7.77778V7.77778H10.1111V9.33333H11.6667V10.8889H10.8889V12.4444H9.33333V14H7.77778V11.6667H9.33333V10.8889H10.1111ZM14 14H10.8889V12.4444H12.4444V10.8889H14V14ZM0 0H6.22222V6.22222H0V0ZM1.55556 1.55556V4.66667H4.66667V1.55556H1.55556ZM7.77778 0H14V6.22222H7.77778V0ZM9.33333 1.55556V4.66667H12.4444V1.55556H9.33333ZM0 7.77778H6.22222V14H0V7.77778ZM1.55556 9.33333V12.4444H4.66667V9.33333H1.55556ZM11.6667 7.77778H14V9.33333H11.6667V7.77778ZM2.33333 2.33333H3.88889V3.88889H2.33333V2.33333ZM2.33333 10.1111H3.88889V11.6667H2.33333V10.1111ZM10.1111 2.33333H11.6667V3.88889H10.1111V2.33333Z"
                        fill="currentColor"
                      />
                    </svg>
                    Scan
                  </Button>
                </Tooltip>
              </Popover>
              <Button
                variant={ButtonVariant.Outline}
                size={ButtonSize.Medium}
                onPress={() => window.open("/download/ios")}
              >
                <I
                  type={IconId.Apple}
                  className="w-[14px] h-[14px] text-icon-decorative mr-2"
                />
                App Store
              </Button>
            </div>
          </>
        )}
        {operatingSystem === "android" && (
          <>
            <div className="dark:hidden h-[696px]">
              <Image
                alt="Daybridge for Android"
                src="/assets/onboarding/mobile/android-light.svg"
                width={720}
                height={696}
                layout="fixed"
              />
            </div>
            <div className="hidden dark:block h-[696px]">
              <Image
                alt="Daybridge for Android"
                src="/assets/onboarding/mobile/android-dark.svg"
                width={720}
                height={696}
                layout="fixed"
              />
            </div>
            <div className="flex flex-row space-x-2 absolute right-0 top-0 p-6">
              <Popover
                content={
                  <div className="p-6 w-60">
                    <h2 className="text-high-contrast text-lg font-bold">
                      Scan to Download
                    </h2>
                    <p className="mb-4 text-low-contrast text-base">
                      Use your phone&apos;s camera to scan this QR code.
                    </p>
                    <Image
                      alt="Play Store QR code"
                      width={208}
                      height={200}
                      src="/assets/onboarding/mobile/code-playstore.svg"
                    />
                  </div>
                }
              >
                <Tooltip content="Open QR code">
                  <Button
                    variant={ButtonVariant.Outline}
                    size={ButtonSize.Medium}
                    className="bg-surface"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-icon-decorative mr-2"
                    >
                      <path
                        d="M10.1111 10.8889V10.1111H7.77778V7.77778H10.1111V9.33333H11.6667V10.8889H10.8889V12.4444H9.33333V14H7.77778V11.6667H9.33333V10.8889H10.1111ZM14 14H10.8889V12.4444H12.4444V10.8889H14V14ZM0 0H6.22222V6.22222H0V0ZM1.55556 1.55556V4.66667H4.66667V1.55556H1.55556ZM7.77778 0H14V6.22222H7.77778V0ZM9.33333 1.55556V4.66667H12.4444V1.55556H9.33333ZM0 7.77778H6.22222V14H0V7.77778ZM1.55556 9.33333V12.4444H4.66667V9.33333H1.55556ZM11.6667 7.77778H14V9.33333H11.6667V7.77778ZM2.33333 2.33333H3.88889V3.88889H2.33333V2.33333ZM2.33333 10.1111H3.88889V11.6667H2.33333V10.1111ZM10.1111 2.33333H11.6667V3.88889H10.1111V2.33333Z"
                        fill="currentColor"
                      />
                    </svg>
                    Scan
                  </Button>
                </Tooltip>
              </Popover>
              <Button
                variant={ButtonVariant.Outline}
                size={ButtonSize.Medium}
                className="bg-surface"
                onPress={() => window.open("/download/android")}
              >
                <I
                  type={IconId.Google}
                  className="w-[14px] h-[14px] text-icon-decorative mr-2"
                />
                Play Store
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}
DownloadMobileAppsFn.displayName = "DownloadMobileApps"

export const DownloadMobileApps = React.memo(
  DownloadMobileAppsFn,
) as typeof DownloadMobileAppsFn
